








































import Vue, { PropType } from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import eventBus from "../event-bus";
import { Settings, NamesLanguage } from "../types";

export default Vue.extend({
  props: { settings: Object as PropType<Settings> },
  data() {
    return {
      names: this.settings.names,
      NamesLanguage,
    };
  },
  watch: {
    names: function (newVal) {
      eventBus.$emit("settings:change:names", newVal);
    },
  },
});
