

































































import Vue, { PropType } from "vue";
import {
  ChallengeState,
  NamesLanguage,
  Recording,
  Settings,
  TaxonMaps,
} from "../types";

import RecordingPlayer from "./RecordingPlayer.vue";

export default Vue.extend({
  components: { RecordingPlayer },
  props: {
    image: String,
    recording: Object as PropType<Recording | null>,
    state: Number as PropType<ChallengeState>,
    taxonMaps: Object as PropType<TaxonMaps>,
    settings: Object as PropType<Settings>,
  },
  data() {
    return { NamesLanguage, ChallengeState };
  },
});
