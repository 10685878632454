



































import Vue, { PropType } from "vue";
import { NamesLanguage, Recording, Settings } from "../types";
import RecordingPlayer from "./RecordingPlayer.vue";

export default Vue.extend({
  components: { RecordingPlayer },
  props: {
    image: String,
    recording: Object as PropType<Recording>,
    recordings: Array as PropType<Recording[]>,
    settings: Object as PropType<Settings>,
  },
  methods: {
    recordingSpeciesWikipediaURL(): string | null {
      if (this.recording) {
        return `https://en.wikipedia.org/w/index.php?title=${this.recording.speciesSci.replace(
          " ",
          "_"
        )}`;
      } else {
        return null;
      }
    },
    recordingSpeciesSciName(): string | null {
      if (this.recording) {
        const speciesEn = this.recording.speciesEn;
        if (this.settings.names == NamesLanguage.English) {
          return speciesEn;
        } else {
          const speciesSci = this.recording.speciesSci;
          if (this.settings.names == NamesLanguage.Scientific) {
            return speciesSci;
          } else {
            return `${speciesSci} (${speciesEn})`;
          }
        }
      } else {
        return null;
      }
    },
  },
});
