











































































import Vue, { PropType } from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import eventBus from "../event-bus";
import NamesSelector from "./NamesSelector.vue";
import { Settings } from "../types";

export default Vue.extend({
  components: { NamesSelector },
  props: {
    settings: Object as PropType<Settings>,
  },
  data() {
    return {
      newSettings: Object.assign({}, this.settings) as Settings,
      sidebarAttributes: {
        overlay: false,
        fullheight: true,
        fullwidth: false,
        right: true,
      },
      open: false,
    };
  },

  watch: {
    "newSettings.commonSpeciesOnly": function (newVal) {
      eventBus.$emit("settings:change:commonSpeciesOnly", newVal);
    },
    "newSettings.songsOnly": function (newVal) {
      eventBus.$emit("settings:change:songsOnly", newVal);
    },
    "newSettings.promptIncludesImages": function (newVal) {
      eventBus.$emit("settings:change:promptIncludesImages", newVal);
    },
    "newSettings.promptIncludesRecording": function (newVal) {
      eventBus.$emit("settings:change:promptIncludesRecording", newVal);
    },
    "newSettings.useFieldModals": function (newVal) {
      eventBus.$emit("settings:change:useFieldModals", newVal);
    },
  },

  mounted: function (): void {
    eventBus.$on("control-panel:show", () => {
      this.open = true;
    });
  },

  methods: {
    showFamilyModal() {
      eventBus.$emit("show:family-selector");
    },
  },
});
