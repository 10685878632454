













































import Vue, { PropType } from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import {
  ChallengeFamily,
  EbirdHotspot,
  EbirdObservation,
  Settings,
  Species,
} from "../types";

export default Vue.extend({
  props: {
    ebirdLocIds: Array as PropType<string[]>,
    ebirdHotspots: Array as PropType<EbirdHotspot[]>,
    locationSpecies: Array as PropType<Species[]>,
    filteredLocationSpecies: Array as PropType<Species[]>,
    challengeFamilies: Map as PropType<Map<string, ChallengeFamily>>,
    commonSpecies: Set as PropType<Set<string>>,
    recentObservations: Array as PropType<EbirdObservation[]>,
    settings: Object as PropType<Settings>,
  },
  computed: {
    nSelectedFamilies(): number {
      return [...this.challengeFamilies.values()].filter(
        ({ selected }) => selected
      ).length;
    },
  },
});
