
















import eventBus from "../event-bus";
import Vue, { PropType } from "vue";
import { ChallengeFamily, NamesLanguage, Settings, TaxonMaps } from "../types";
export default Vue.extend({
  props: {
    challengeFamilies: Map as PropType<Map<string, ChallengeFamily>>,
    taxonMaps: Object as PropType<TaxonMaps>,
    settings: Object as PropType<Settings>,
  },

  data() {
    return {
      isModalActive: false,
    };
  },

  mounted() {
    eventBus.$on("show:family-selector", () => (this.isModalActive = true));
  },

  computed: {
    classes(): object {
      return {
        section: true,
        "has-text-grey-light": true,
        "is-size-7-mobile": this.settings.names == NamesLanguage.Both,
      };
    },
  },

  methods: {
    selectFamily(family: string, val: boolean): void {
      eventBus.$emit("family:select", family, val);
    },

    formatFamily(familySci: string): string {
      if (this.settings.names == NamesLanguage.Scientific) {
        return familySci;
      } else {
        const familyEn = this.taxonMaps.familySci2En.get(familySci) as string;
        if (this.settings.names == NamesLanguage.Both)
          return `${familySci} (${familyEn})`;
        else {
          return familyEn;
        }
      }
    },
  },
});
