






















import Vue, { PropType } from "vue";

import { SpeciesImages } from "../types";

export default Vue.extend({
  props: {
    option: String,
    images: Array as PropType<SpeciesImages[]>,
    imageLabelFn: Function,
  },
});
